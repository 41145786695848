// 城市组件
import InlandProvinceCityRegion from "@/component/inland-province-city-region/index.vue";
// 上传文件组件
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client/index";
// 行业
import consumer_credit_getEnum from '@/lib/data-service/haolv-default/consumer_credit_getEnum.js';
// 行业
import consumer_web_industry_selectIndustry from '@/lib/data-service/haolv-default/consumer_web_industry_selectIndustry.js';
// 添加公司授信信息
import consumer_credit_addCreditApply from '@/lib/data-service/haolv-default/consumer_credit_addCreditApply.js';
// 获取公司授信信息
import consumer_credit_queryCreditApply from '@/lib/data-service/haolv-default/consumer_credit_queryCreditApply.js';

export default {
    components: {
        InlandProvinceCityRegion,
    },
    data() {
        return {
            loading: false,
            form: {
                // companyName: '',
                // companyIntroduce: '',
                // industryName: '',
                // scale: '',
                // areaProvince: '', // 省
                // areaProvinceName: '',
                // areaCity: '', // 市
                // areaCityName: '',
                // areaRegion: '', // 区
                // areaRegionName: '',
                // companyProfile: '',
                // accessoryUrl: '',
                // name: '',
                // phone: '',
                // businessLicenseNum: '',
                // businessLicensePic: '',
                estimateConsumption: '', // 预计月消费
                settlementInterval: '', // 结算周期
                invoiceType: '', // 发票类型
                auditReportPic: '', // 审计报告
                auditReportName: '',
            },
            formRules: {
                industryName: [{required: true, message: '所属行业不能为空', trigger: ['blur', 'change']}],
                scale: [{required: true, message: '企业规模不能为空', trigger: ['blur', 'change']}],
                name: [{required: true, message: '联系人姓名不能为空', trigger: ['blur', 'change']}],
                phone: [{required: true, message: '联系人电话不能为空', trigger: ['blur', 'change']}],
                businessLicenseNum: [{required: true, message: '营业执照号不能为空', trigger: ['blur', 'change']}],
            },
            cityRules: {
                form: {
                    areaProvince: 'areaProvince',
                    areaCity: 'areaCity',
                    areaRegion: 'areaRegion',
                },
                rules: {
                    areaProvince: [{required: true, message: '请选择省份', trigger: ['blur', 'change']}],
                    areaCity: [{required: true, message: '请选择城市', trigger: ['blur', 'change']}],
                    areaRegion: [{required: true, message: '请选择区', trigger: ['blur', 'change']}],
                },
            },
            // 城市组件数据
            locationCity: {},
            allocation: {
                industryType: [],
                enterpriseScale: [
                    // {value: 1, label: "0~20人"},
                    // {value: 2, label: "20~50人"},
                    // {value: 3, label: "50~100人"},
                    // {value: 4, label: "100~300人"},
                    // {value: 5, label: "300~1000人"},
                    // {value: 6, label: "1000人以上"},
                ],
                estimateConsumptionList: [
                    // {value: 1, label: "2"},
                    // {value: 2, label: "3"},
                    // {value: 3, label: "4"},
                    // {value: 4, label: "5"},
                    // {value: 5, label: "6"},
                    // {value: 6, label: ">10"},
                ],
                settlementIntervalList: [
                    // {value: 1, label: "标准自然月"},
                    // {value: 2, label: "每月6日到次月5日"},
                    // {value: 3, label: "每月11日到次月10日"},
                    // {value: 4, label: "每月16日到次月15日"},
                    // {value: 5, label: "每月26日到次月25日"}
                ],
                invoiceTypeList: [
                    // {value: 0, label: "纸质发票"},
                    // {value: 1, label: "电子发票"},
                ]
            },
            logoLoading: false,
            businessLicenseNumLoading: false,
        }
    },
    methods: {
        init() {
            const __this = this;
            consumer_credit_queryCreditApply().then((res) => {
                if (res.datas && res.datas.accountStatus === 4) {
                    __this.$router.push({
                        name: `admin-finance-credit-details`
                    });
                }
            });

            consumer_web_industry_selectIndustry().then((res) => {
                __this.allocation.industryType = res.datas;
            });

            consumer_credit_getEnum().then(res => {
                __this.allocation.settlementIntervalList = res.datas[0];
                __this.allocation.invoiceTypeList = res.datas[1];
                __this.allocation.estimateConsumptionList = res.datas[2];
                __this.allocation.enterpriseScale = res.datas[3];
            });
            // console.log(__this.settlementIntervalList)
        },
        // 上传公司logo
        change_upload(file) {
            const __this = this;
            __this.$refs.uploadLogo.clearFiles();
            const myUpLoad = new OssClient();
            const isJPG_PNG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/jpg' || file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 < 10240;
            if (!isJPG_PNG) {
                __this.$message.error('上传图片格式有误!');
                return false;
            }
            if (!isLt10M) {
                __this.$message.error('公司LOGO大小不能超过 10MB!');
                return false;
            }
            __this.logoLoading = true;
            myUpLoad.multipartUpload({
                file: file.raw,
                progress: function* (p) {},
            }).then((data) => {
                __this.logoLoading = false;
                if(isJPG_PNG && isLt10M) {
                    __this.form.accessoryUrl = data.url;
                }
            }).catch((error) => {
                __this.logoLoading = false;
                __this.$message.error("上传失败");
            });
        },
        deleteLogo() {
            const __this = this;
            __this.form.accessoryUrl = '';
            __this.$refs.uploadLogo.clearFiles();
        },
        // 上传公司logo end

        // 上传营业执照
        business_license_upload(file) {
            const __this = this;
            __this.$refs.uploadBusinessLicense.clearFiles();
            const myUpLoad = new OssClient();
            const isJPG_PNG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/jpg' || file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 < 10240;
            if (!isJPG_PNG) {
                __this.$message.error('上传图片格式有误!');
                return false;
            }
            if (!isLt10M) {
                __this.$message.error('营业执照大小不能超过 10MB!');
                return false;
            }
            __this.businessLicenseNumLoading = true;
            myUpLoad.multipartUpload({
                file: file.raw,
                progress: function* (p) {},
            }).then((data) => {
                __this.businessLicenseNumLoading = false;
                if(isJPG_PNG && isLt10M) {
                    __this.form.businessLicensePic = data.url;
                }
            }).catch((error) => {
                __this.businessLicenseNumLoading = false;
                __this.$message.error("上传失败");
            });
        },
        deletePic() {
            const __this = this;
            __this.form.businessLicensePic = '';
            __this.$refs.uploadBusinessLicense.clearFiles();
        },
        // 上传营业执照 end

        // 上传审计报告
        upload_audit_report(file) {
            const __this = this;
            const size = file.size / 1024;
            if (size > 10240) {
                __this.$message.warning("审计报告大小不能超过 10MB!");
                __this.$refs.uploadAuditReport.clearFiles();
            } else {
                const myUpLoad = new OssClient();
                __this.form.auditReportName = file.name;
                myUpLoad.multipartUpload({
                    file: file.raw,
                    progress: function*(p) {},
                }).then((data) => {
                    __this.form.auditReportName = file.name;
                    __this.form.auditReportPic = data.url;
                }).catch(() => {
                    __this.$message.error("上传失败");
                    __this.form.auditReportName = '';
                    __this.form.auditReportPic = '';
                    __this.$refs.uploadAuditReport.clearFiles();
                });
            }
        },
        delete_item() {
            const __this = this;
            __this.form.auditReportName = '';
            __this.form.auditReportPic = '';
            __this.$refs.uploadAuditReport.clearFiles();
        },
        downAccessory() { // 点击附件下载
            const __this = this;
            const eleLink = document.createElement('a');
            eleLink.href = __this.form.auditReportPic;
            eleLink.download = __this.form.auditReportName;
            document.body.appendChild(eleLink);
            eleLink.click();
            document.body.removeChild(eleLink);
        },
        // 上传审计报告 end

        onSubmit() {
            const __this = this;
            __this.loading = true;
            consumer_credit_addCreditApply(__this.form).then(res => {
                __this.$message({
                    message: '提交成功',
                    type: 'success',
                    onClose: () => {
                        __this.$router.push({
                            name: `admin-finance-credit-details`
                        });
                    }
                });
            }).catch(error => {
                __this.$message.error('提交失败！')
            }).finally(() => {
                __this.loading = false;
            })
        }
    },
    created() {
    },
    mounted() {
    },
    activated() {
        const __this = this;
        __this.init();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        locationCity: {
            handler(val) {
                const form = this.form;
                form.areaProvince = val.areaProvince;
                form.areaProvinceName = val.areaProvinceName;
                form.areaCity = val.areaCity;
                form.areaCityName = val.areaCityName;
                form.areaRegion = val.areaRegion;
                form.areaRegionName = val.areaRegionName;
            },
            deep: true,
        },
    },
    computed: {},
    filters: {},

}
