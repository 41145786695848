// 获取公司授信信息：http://219.128.52.2:18765/doc.html?tdsourcetag=s_pctim_aiomsg#/tmc-biz/t-us-company-credit-controller/queryCreditApplyUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_credit_queryCreditApply = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/credit/queryCreditApply',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_credit_queryCreditApply;